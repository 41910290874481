import React from 'react';

const AppFooter = () => (
  <div className="app-footer">
    <div className="app-footer-column">
      <span id="footer-column-header">ACTIVITY</span>
      <a href="/projects">Projects</a>
      {/*<a href="/events">Events</a>*/}
    </div>
    <div className="app-footer-column">
      <span id="footer-column-header">HELP US</span>
      <a href="/fundraising">Fundraising</a>
      {/*<a href="/fundraising-status">Project Funding</a>*/}
    </div>
    <div className="app-footer-column">
      <span id="footer-column-header">Contact Us</span>
      <a href="mailto:michael.fitch@medexinternational.net">General Questions or Help</a>
      <a href="mailto:michael.fitch@medexinternational.net">Fundraising Questions</a>
      <a href="https://www.facebook.com/MedExIntl">Facebook Page</a>
    </div>
    <div id="copyright">© 2020 - MedEx International</div>
  </div>
);

export default AppFooter;
