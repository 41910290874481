const initialData = {
  inventoryData: [],
  isFetching: false,
  isError: '',
};

const inventoryReducer = (state = initialData, action) => {
  switch (action.type) {
    case 'GET_INVENTORY_STARTED':
      return {
        ...state,
        inventoryData: [],
        isFetching: true,
        isError: '',
      };
    case 'GET_INVENTORY_SUCCESS':
      return {
        ...state,
        inventoryData: action.data,
        isFetching: false,
        isError: '',
      };
    case 'GET_USER_FAILURE':
      return {
        ...state,
        isFetching: false,
        isError: action.error,
      };
    default:
      return state;
  }
};

export default inventoryReducer;
