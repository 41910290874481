import React from 'react';
import { Column, Row, Tile } from 'carbon-components-react';
import MoonCrateLogo from '../images/mooncrate-logo.jpg';
import NaloxoneTraining from '../images/2022_Naloxone_Training_Event.jpg';

const Events = () => (
  <div>
    <Tile className="about-header">
      <h1>Fundraising Event Calendar</h1>
      <p>
        <em>
          All our upcoming and past fundraising and community events are below. Please check back
          often to see each of our events as they come up online.
        </em>
      </p>
    </Tile>
    <Tile className="content-tile">
      <Row className="section-title">
        <h2>Naloxone Training Event/ Distribution Days (February 2022, May 2022, September 2022, December 2022)</h2>
        <Column style={{ margin: '50px', textAlign: 'center' }}>
          <img alt="" border="0" src={NaloxoneTraining} width="500px" height="500px" />
        </Column>
      </Row>
      <Row className="section-title">
        <h2>2021 MoonCrate Partnership</h2>
      </Row>
      <Row>
        <Column>
          <h4>
            <b>Date: January-December 2021</b>
          </h4>
          <br />
          <h5>
            MedEx International is excited to share the wonderful news that we were named
            MoonCrate's 2021 Charity Partner. We are very grateful for this opportunity to work with
            Kendell, the owner of a company so passionate about self-care and community-care. Please
            consider supporting this partnership by purchasing your handmade candles and soaps from
            MoonCrate!
          </h5>
          <br />
        </Column>
        <Column style={{ margin: '50px', textAlign: 'center' }}>
          <img alt="" border="0" src={MoonCrateLogo} width="500px" height="500px" />
        </Column>
      </Row>
    </Tile>
    <Tile className="content-tile">
      <Row className="section-title">
        <h2>2020 Bike-A-Thon</h2>
      </Row>
      <Row>
        <Column>
          <h4>
            <b>Date: October 10th, 2020</b>
          </h4>
        </Column>
        <Column style={{ margin: '50px', textAlign: 'center' }}>
          <iframe
            title="BikeAThon Poster"
            height="1000px"
            width="100%"
            src="https://spark.adobe.com/post/qk4QQWgnI59r6/"
            allowFullScreen="true"
          />
        </Column>
      </Row>
    </Tile>
  </div>
);

export default Events;
