import React from 'react';
import { Grid, ListItem, UnorderedList, Row, Tile } from 'carbon-components-react';
import NarcanKit from '../../../images/Narcan_Kits.jpg';

const NeedleManagementTab = () => {
  return (
    <div style={{ textAlign: 'left' }}>
      <Grid>
        <Row className="section-title">
          <h2>Opioid Overdose Prevention & Naloxone Training</h2>
        </Row>
        <Row>
          <Tile className="content-tile">
            <p>
              MedEx International is a Drug Overdose Prevention Program (DOPP) and an Overdose
              Education and Naloxone Distribution (ONED) program registered with the Illinois
              Department of Human Services, Division of Substance Use Prevention and Recovery
              (IDHS/SUPR).
            </p>
          </Tile>
        </Row>
        <Row>
          <Tile className="content-tile">
            <p>We host training events where our objective is to help others:</p>
            <UnorderedList nested={false} style={{ paddingLeft: 50 }}>
              <ListItem>Understand the opioid crisis occurring in Illinois and across the United States</ListItem>
              <ListItem>Learn how to identify and respond to an opioid overdose</ListItem>
              <ListItem>Learn how to administer naloxone</ListItem>
            </UnorderedList>
          </Tile>
        </Row>
        <Row>
          <Tile className="content-tile">
            <p>We assemble and distribute Narcan Kits that contain: </p>
            <UnorderedList nested={false} style={{ paddingLeft: 50 }}>
              <ListItem>2 Doses of NARCAN Nasal Spray </ListItem>
              <ListItem>Gloves </ListItem>
              <ListItem>NARCAN Quick Guide </ListItem>
              <ListItem>MedEx International Contact Information </ListItem>
            </UnorderedList>
          </Tile>
        </Row>
        <Row>
          <img alt="Narcan Kit" src={NarcanKit} class="center-image" width="30%"></img>
        </Row>
      </Grid>
    </div>
  );
};

export default NeedleManagementTab;
