import React from 'react';
import { Tab, Tabs, Tile } from 'carbon-components-react';
import {
  BorderAidTab,
  HomeFirstAid,
  NeedleManagementTab,
  ShelterCareTab,
  ReentrySupportTab,
} from './components';

const Projects = () => {
  return (
    <div>
      <Tile className="about-header">
        <h1>Current Projects</h1>
        <p>
          <em>
            There are Five pillars of MedEx International: Reentry Support, Border Aid, Shelter/
            Clinic Care, Home First Aid Kits, Opioid Overdose Prevention and Naloxone Training.
          </em>
        </p>
      </Tile>
      <div style={{ textAlign: 'left' }}>
        <p>Please click on the following tabs to learn more about our different initiatives.</p>
      </div>
      <div>
        <Tabs
          aria-label="listbox"
          iconDescription="show menu options"
          role="navigation"
          selected={0}
          selectionMode="automatic"
          triggerHref="#"
          type="container"
        >
          <Tab
            href="#"
            id="border-aid-tab"
            label="Border Aid"
            role="presentation"
            selected={false}
            tabIndex={2}
          >
            <BorderAidTab />
          </Tab>
          <Tab
            href="#"
            id="home-first-aid"
            label="Home First Aid Kits"
            role="presentation"
            selected={false}
            tabIndex={4}
          >
            <HomeFirstAid />
          </Tab>
          <Tab
            href="#"
            id="shelter-care-tab"
            label="Shelter/ Clinic Care"
            role="presentation"
            selected={true}
            tabIndex={0}
          >
            <ShelterCareTab />
          </Tab>
          <Tab
            href="#"
            id="needle-management-tab"
            label="Opioid Overdose Prevention & Naloxone Training"
            role="presentation"
            selected={false}
            tabIndex={3}
          >
            <NeedleManagementTab />
          </Tab>
          <Tab
            href="#"
            id="reentry-support-tab"
            label="Reentry Support"
            role="presentation"
            selected={false}
            tabIndex={4}
          >
            <ReentrySupportTab />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Projects;
