import React from 'react';
import './App.css';
import { AppHeader } from './components';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { About, Contact, Home, NotFound, Fundraising, ThankYou, Projects, Events } from './pages';
import { Content } from 'carbon-components-react';
import AppFooter from './components/AppFooter';

function App() {
  return (
    <div className="App">
      <AppHeader />
      <Content className="content-body">
        <Router>
          <Switch>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/events">
              <Events />
            </Route>
            <Route path="/fundraising">
              <Fundraising />
            </Route>
            <Route path="/thankyou">
              <ThankYou />
            </Route>
            <Route path="/projects">
              <Projects />
            </Route>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Router>
      </Content>
      <AppFooter />
    </div>
  );
}

export default App;
