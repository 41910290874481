import React from 'react';
import { Column, Grid, ListItem, UnorderedList, Row, Tile } from 'carbon-components-react';
import FirstAidItems from '../../../images/first-aid-items.png';
import StackedFirstAid from '../../../images/Home_First_Aid_Kits.jpg';

const HomeFirstAidTab = () => {
  return (
    <div style={{ textAlign: 'left' }}>
      <Grid>
        <Row className="section-title">
          <h2>Home First Aid Kits</h2>
        </Row>
        <Row>
          <Tile className="content-tile">
            <p>
              We assemble home first aid kits for individuals and families transitioning from
              homelessness to permanent housing in Chicago.
            </p>
          </Tile>
        </Row>
        <Row>
          <Tile className="content-tile">
            <Row>
              <Column>
                <h4>
                  <strong>These kits include the following items:</strong>
                </h4>
                <br />
                <UnorderedList nested={false} style={{ paddingLeft: 50 }}>
                  <ListItem>Cotton balls</ListItem>
                  <ListItem>Gel pack</ListItem>
                  <ListItem>Gauze bandage roll</ListItem>
                  <ListItem>Sting relief</ListItem>
                  <ListItem>Hydrocortisone cream</ListItem>
                  <ListItem>Antibiotic ointment</ListItem>
                  <ListItem>Bactine cleansing spray</ListItem>
                  <ListItem>Body lotion</ListItem>
                  <ListItem>Bandages</ListItem>
                  <ListItem>Burn gel</ListItem>
                  <ListItem>Cough drops</ListItem>
                  <ListItem>Tweezers</ListItem>
                  <ListItem>Ace bandage</ListItem>
                  <ListItem>Adhesive tape</ListItem>
                  <ListItem>Digital thermometer</ListItem>
                </UnorderedList>
              </Column>
              <Column>
                <img
                  alt="First Aid Items"
                  src={FirstAidItems}
                  class="center-image"
                  width="30%"
                ></img>
              </Column>
              <Column>
                <img
                  alt="Stacked First Aid Boxes"
                  src={StackedFirstAid}
                  class="center-image"
                  width="30%"
                ></img>
              </Column>
            </Row>
          </Tile>
        </Row>
      </Grid>
    </div>
  );
};

export default HomeFirstAidTab;
