import React from 'react';
import { Tile, Row, Column } from 'carbon-components-react';
import { ImageTextTile } from '../components';
import medexM from '../images/medex-m.png';
import medexE from '../images/medex-e.png';
import medexD from '../images/medex-d.png';
import medexX from '../images/medex-x.png';
import medexLogo from '../images/medex_logo.png';
import dorothyDay from '../images/dorothydayquote.png';
import goodNeighbor from '../images/goodneighbor.png';
import housingForward from '../images/housingforward.png';
import mateo from '../images/mateo.png';
import medline from '../images/medline.png';
import sos from '../images/sos.png';
import kolbe from '../images/kolbe.jpeg';

const Home = () => (
  <div>
    <img alt="Medex Logo" src={medexLogo} />
    <div className="mobile-header">
      <p>
        This website is meant to be viewed on desktop. Please visit us from your computer. Some
        layouts and functions may not be functional on mobile.
      </p>
    </div>
    <Tile className="about-header">
      <h3>Mission Statement:</h3>
      <p>
        <br />
        <em>
          MedEx International’s mission is to help individuals in need
          maintain their <b>dignity</b> and <b>good health</b>.{' '}
        </em>
      </p>
    </Tile>
    <div className="about-tile-container">
      <ImageTextTile imageUrl={medexM} altText="M" title="Who Are We?">
        <ul>
          <li>An official not for profit 501(c)(3) corporation</li>
          <li>Founded in 2017 by Michael Fitch</li>
          <li>Powered by a team of caring, dedicated, and knowledgeable volunteers</li>
        </ul>
      </ImageTextTile>
      <ImageTextTile imageUrl={medexE} altText="E" title="What is the Problem?">
        <ul>
          <li>Individuals experiencing homelessness and others
          in transition suffer from various health concerns due
          to limited access to personal hygiene items, first
          aid supplies and basic medical care.</li>
        </ul>
      </ImageTextTile>
      <ImageTextTile imageUrl={medexD} altText="D" title="What do we do?">
        <ul>
          <li>Equip other non-profit organizations with medical supplies and equipment</li>
          <li>Assemble care packages and home first aid kits</li>
          <li>Host naloxone training events</li>
        </ul>
      </ImageTextTile>
      <ImageTextTile imageUrl={medexX} altText="X" title="Our Ask of You">
        <ul>
          <li>We are only able to accomplish our mission with the help of outside funding</li>
          <li>
            If you are willing and able to donate, please click on the{' '}
            <a href="/fundraising">fundraising</a> tab to learn more about our funding philosophy
            and the different ways you can help.
          </li>
        </ul>
      </ImageTextTile>
    </div>
    <Tile className="about-header">
      <h3>Operating Model</h3>
      <br />
      <p>
        Our main focus is forming partnerships with other non-profit organizations from the
        community. This allows us to grant these organizations access to the supplies necessary for
        them to further accomplish their missions without financial or operational burden. We believe
        that the best way we can provide help is by working within the existing network of non-profit
        organizations, because we have found that this strengthens the delivery of their missions by
        expanding access to necessary supplies and coordinating group efforts.
      </p>
      <br />
      <br />
      <div>
        <Row>
          <Column width="25%" align="center">
            <img alt="" src={housingForward} height="100px" />
          </Column>
          <Column width="25%" align="center">
            <img alt="" src={mateo} height="100px" />
          </Column>
          <Column width="25%" align="center">
            <img alt="" src={goodNeighbor} height="100px" />
          </Column>
          <Column width="25%" align="center">
            <img alt="" src={kolbe} height="100px" />
          </Column>
        </Row>
      </div>
      <br />
      <br />
      <p>
        <b>Infrastructure:</b> To support our operations, we have rented space to temporarily store donated and purchased supplies, 
        and a member of our board engineered a custom inventory management system.

        Additionally, we have established relationships with medical and equipment suppliers allowing
        us to purchase at discounted rates and providing us with a reliable supply chain.
        <br />
      </p>
      <br />
      <br />
      <div>
        <Row>
          <Column width="50%" align="center">
            <img alt="" src={sos} height="100px" />
          </Column>
          <Column width="50%" align="center">
            <img alt="" src={medline} height="100px" />
          </Column>
        </Row>
      </div>
    </Tile>
    <img
      src={dorothyDay}
      alt="People say, what is the sense of our small effort? They cannot see that we must lay one brick at a time, take one step at a time. A pebble cast into a pond causes ripples that spread in all directions. Each one of our thoughts, words and deeds is like that. No one has the right to down and feel hopeless. There is too much work to do.”"
      className="base-image"
    />
  </div>
);

export default Home;
