import React from 'react';
import { Grid, Row, Tile } from 'carbon-components-react';

const ReentrySupportTab = () => {
  return (
    <div style={{ textAlign: 'left' }}>
      <Grid>
        <Row className="section-title">
          <h2>Reentry Support</h2>
        </Row>
        <Row>
          <Tile className="content-tile">
            <p>
              We provide personal hygiene supplies to a Chicago
              based reentry organization that has a mission to serve individuals recently
              impacted by incarceration at Cook County Jail and Illinois Department of
              Corrections.
              
              This is a new initiative as of Fall 2022, but so far, we have already equipped
              this team with enough personal hygiene supplies for over 200 individuals.
            </p>
          </Tile>
        </Row>
      </Grid>
    </div>
  );
};

export default ReentrySupportTab;
