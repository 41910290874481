import React from 'react';
import { Tile } from 'carbon-components-react';

class ImageTextTile extends React.Component {
  render() {
    return (
      <Tile light className="about-tile">
        <img src={this.props.imageUrl} alt={this.props.altText} />
        <h3>{this.props.title}</h3>
        <div className="about-text">{this.props.children}</div>
      </Tile>
    );
  }
}

export default ImageTextTile;
