import React from 'react';

const NotFound = () => (
  <div>
    <p>
      Sorry, you&apos;ve cliked a link we don&apos;t understand. Please go back <a href="/">home</a>{' '}
      or email us to let us know where we went wrong.
    </p>
  </div>
);

export default NotFound;
