import React from 'react';
import { Column, Grid, Row, Tile, UnorderedList, ListItem } from 'carbon-components-react';
import BorderAidLogo from '../../../images/border-aid-logo.jpg';
import WaterRoses from '../../../images/water-roses.jpg';
import BubblesPic from '../../../images/bubbles.jpeg';
import DiapersPic from '../../../images/diapers.jpeg';
import GirlPic from '../../../images/girl.jpeg';

const BorderAidTab = () => {
  return (
    <div style={{ textAlign: 'left' }}>
      <Grid>
        <Row className="section-title">
          <h2>Border Aid</h2>
        </Row>
        <Row>
          <Tile className="content-tile">
            <p>
              We provide a range of supplies needed by asylum seekers who have entered the United
              States as well as the thousands of individuals and families who are currently waiting
              at the crossings of the U.S./ Mexico border.
            </p>
          </Tile>
        </Row>
        <Row>
          <Column>
            <Row>
              <Tile className="content-tile">
                <h4>
                  <strong>Border Crossings</strong>
                </h4>
                <br />
                <p>
                  We work with our partners in Texas to provide care packages to those asylum
                  seekers who have entered the United States. Including our Personal Hygiene Kits,
                  Wound Care Kit, Feminine Hygiene Kit, Baby Care Kits, Face Masks and Gloves. In
                  each kit, we include this small message of love and encouragement.
                </p>
              </Tile>
            </Row>
          </Column>
          <Column>
            <Row>
              <Tile className="content-tile">
                <h4>
                  <strong>Migrant Camps in Mexico</strong>
                </h4>
                <br />
                <p>
                  We provide baby care supplies, first aid supplies, undergarments and tents to
                  those residing in the migrant camps of Matamoros, Mexico. Also, we often include
                  bubbles for the kids with our deliveries.
                </p>
              </Tile>
            </Row>
          </Column>
        </Row>
        <Row>
          <Column>
            <Row>
              <Column>
                <Tile className="kit-tile">
                  <p>
                    <strong>Personal Hygiene Kit</strong>
                  </p>
                  <br />
                  <UnorderedList style={{ paddingLeft: 20 }}>
                    <ListItem>Toothbrush and Toothpaste</ListItem>
                    <ListItem>Shampoo and Body Wash</ListItem>
                    <ListItem>Deodorant</ListItem>
                    <ListItem>Body Lotion</ListItem>
                    <ListItem>Combs</ListItem>
                    <ListItem>Fingernail Clippers</ListItem>
                    <ListItem>Disposable Tweezer</ListItem>
                    <ListItem>Sunscreen Pouches</ListItem>
                    <ListItem>Insect Repellant</ListItem>
                  </UnorderedList>
                </Tile>
              </Column>
              <Column>
                <Tile className="kit-tile">
                  <p>
                    <strong>Wound Care Kit</strong>
                  </p>
                  <br />
                  <UnorderedList style={{ paddingLeft: 20 }}>
                    <ListItem>Bandages</ListItem>
                    <ListItem>Gauze Pads</ListItem>
                    <ListItem>First Aid Tape</ListItem>
                    <ListItem>Elastic Wrap</ListItem>
                    <ListItem>Antibiotic Ointment</ListItem>
                    <ListItem>Antiseptic Wipes</ListItem>
                    <ListItem>Sterile Saline Wipes</ListItem>
                  </UnorderedList>
                </Tile>
              </Column>
            </Row>
            <Row>
              <Column>
                <Tile className="kit-tile">
                  <p>
                    <strong>Feminine Hygiene Kit</strong>
                  </p>
                  <br />
                  <UnorderedList style={{ paddingLeft: 20 }}>
                    <ListItem>Sanitary Pads</ListItem>
                    <ListItem>Tampons</ListItem>
                    <ListItem>Feminine Wipes</ListItem>
                  </UnorderedList>
                </Tile>
              </Column>
              <Column>
                <Tile className="kit-tile">
                  <p>
                    <strong>Baby Care Kit</strong>
                  </p>
                  <br />
                  <UnorderedList style={{ paddingLeft: 20 }}>
                    <ListItem>Diapers</ListItem>
                    <ListItem>Baby Shampoo</ListItem>
                    <ListItem>Baby Wipes</ListItem>
                  </UnorderedList>
                </Tile>
              </Column>
            </Row>
            <Row>
              <Column>
                <img
                  alt="Water Rose Logo"
                  className="base-image"
                  src={WaterRoses}
                  style={{
                    height: 550,
                    width: 350,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                />
              </Column>
            </Row>
          </Column>
          <Column>
            <Row>
              <img
                alt="Border Aid Logo"
                src={BorderAidLogo}
                style={{
                  height: 90,
                  width: 375,
                  display: 'block',
                  marginBottom: '20px',
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
              />
            </Row>
            <Row>
              <Column width="33%">
                <img alt="" src={BubblesPic} width="100%" height="100%"></img>
              </Column>
              <Column width="33%">
                <img alt="" src={DiapersPic} width="100%" height="100%"></img>
              </Column>
              <Column width="33%">
                <img alt="" src={GirlPic} width="100%" height="100%"></img>
              </Column>
            </Row>
          </Column>
        </Row>
      </Grid>
    </div>
  );
};

export default BorderAidTab;
