import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInventory } from '../actions/inventory';
import { getInventoryData, getInventoryIsFetching } from '../selectors';
import { DataTable } from 'carbon-components-react';
import LoadingIndicator from '../components/LoadingIndicator';

const { TableContainer, Table, TableHead, TableRow, TableBody, TableCell, TableHeader } = DataTable;

const ManageInventory = () => {
  const dispatch = useDispatch();

  const inventoryData = useSelector(getInventoryData);
  const isFetching = useSelector(getInventoryIsFetching);

  useEffect(() => {
    dispatch(fetchInventory());
  }, [dispatch]);

  const headers = [
    {
      header: 'Name',
      key: 'item_name',
    },
    {
      header: 'Status',
      key: 'item_status',
    },
    {
      header: 'Item Type',
      key: 'item_type',
    },
    {
      header: 'Retrieval Type',
      key: 'retrieval_type',
    },
    {
      header: 'Item Size',
      key: 'item_size',
    },
  ];

  return (
    <>
      <DataTable
        rows={inventoryData}
        headers={headers}
        render={({ rows, headers, getHeaderProps }) => (
          <TableContainer title="Manage Inventory">
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map(header => (
                    // eslint-disable-next-line react/jsx-key
                    <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <TableRow key={row.id}>
                    {row.cells.map(cell => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      />
      <LoadingIndicator active={isFetching} />
    </>
  );
};

export default ManageInventory;
