import React from 'react';
import { Column, Grid, ListItem, UnorderedList, Row, Tile } from 'carbon-components-react';
import PrivacyScreen from '../../../images/privacy-screen.jpg';
import ShelterCareLogo from '../../../images/shelter-care-logo.png';

const ShelterCareTab = () => {
  return (
    <div style={{ textAlign: 'left' }}>
      <Grid>
        <Row className="section-title">
          <h2>Shelter/ Clinic Care</h2>
        </Row>
        <Row>
          <Tile className="content-tile">
            <p>
              We work closely with leaders of homeless shelters to determine the specific needs of
              their clients. Specific projects with shelters include:
            </p>
          </Tile>
        </Row>
        <Row>
          <Column>
            <Row>
              <Tile className="content-tile">
                <h4>
                  <strong>Shelter Site First Aid Kits</strong>
                </h4>
                <br />
                <UnorderedList nested={false} style={{ paddingLeft: 50 }}>
                  <ListItem>Cough Drops</ListItem>
                  <ListItem>Anti-Diarrheal</ListItem>
                  <ListItem>Pain Medicine – Ibuprofen, Aspirin, Tylenol</ListItem>
                  <ListItem>Antacid Tablets</ListItem>
                  <ListItem>Vitamins</ListItem>
                  <ListItem>Eye Drops</ListItem>
                  <ListItem>Pain Relief Gel</ListItem>
                  <ListItem>Instant Ice Packs</ListItem>
                  <ListItem>Patch Bandages</ListItem>
                  <ListItem>Plastic Adhesive Bandages</ListItem>
                  <ListItem>Triple Antibiotic Ointment</ListItem>
                  <ListItem>Antiseptic Wipes</ListItem>
                  <ListItem>Sterile Saline Wash</ListItem>
                  <ListItem>Sterile Pro Gauze</ListItem>
                  <ListItem>First Aid Tape</ListItem>
                  <ListItem>Elastic Wrap with Clips</ListItem>
                  <ListItem>Face Masks and Gloves</ListItem>
                </UnorderedList>
              </Tile>
            </Row>
          </Column>
          <Column>
            <Row>
              <Tile className="content-tile">
                <Row>
                  <Column>
                    <h4>
                      <strong>Shelter Medical Clinics</strong>
                    </h4>
                    <br />
                    <p>
                      We supply a medical clinic that offers medical care to homeless individuals.
                      Supplies we have donated:
                    </p>
                    <br />
                    <UnorderedList nested={false} style={{ paddingLeft: 50 }}>
                      <ListItem>Scales</ListItem>
                      <ListItem>Blood Pressure Cuffs</ListItem>
                      <ListItem>Stethoscopes</ListItem>
                      <ListItem>Glucometers, with test trips and lancets</ListItem>
                      <ListItem>Medical Privacy Screens</ListItem>
                      <ListItem>Automated External Defibrillators (AEDs)</ListItem>
                      <ListItem>CPR Fast Response Kits</ListItem>
                    </UnorderedList>
                  </Column>
                  <Column>
                    <img alt="" src={PrivacyScreen} width="70%"></img>
                  </Column>
                </Row>
              </Tile>
              <img alt="" src={ShelterCareLogo} class="center-image" width="70%"></img>
            </Row>
          </Column>
        </Row>
      </Grid>
    </div>
  );
};

export default ShelterCareTab;
