import React from 'react';
import {
  Header,
  HeaderName,
  // HeaderGlobalAction,
  // HeaderGlobalBar,
  HeaderNavigation,
  HeaderMenuItem,
} from 'carbon-components-react';
// import {Login32} from "@carbon/icons-react";

const AppHeader = () => (
  <Header aria-label="Inventory Management Solution">
    <HeaderName id="header-name" href="/" prefix="">
      MedEx International
    </HeaderName>
    <HeaderNavigation aria-label="Inventory Management Solution">
      {/*todo: Mobile friendly header*/}
      <HeaderMenuItem id="home-menu-item" href="/">
        Home
      </HeaderMenuItem>
      {/*<HeaderMenuItem id="about-menu-item" href="/about">*/}
      {/*  About*/}
      {/*</HeaderMenuItem>*/}
      {/*<HeaderMenuItem id="contact-menu-item" href="/contact">*/}
      {/*  Contact*/}
      {/*</HeaderMenuItem>*/}
      <HeaderMenuItem id="projects-menu-item" href="/projects">
        Projects
      </HeaderMenuItem>
      <HeaderMenuItem id="fundraising-menu-item" href="/fundraising">
        Fundraising
      </HeaderMenuItem>
      <HeaderMenuItem id="events-menu-item" href="/events">
        Events
      </HeaderMenuItem>
    </HeaderNavigation>
    {/* <HeaderGlobalBar>
      <HeaderGlobalAction
        id="login-button"
        aria-label="Login"
        onClick={() => {}}
      >
        <Login32/>
      </HeaderGlobalAction>
    </HeaderGlobalBar> */}
  </Header>
);

export default AppHeader;
