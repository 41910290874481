import React from 'react';
import { Column, Grid, ListItem, Row, Tile, UnorderedList } from 'carbon-components-react';
import PaypalDonate from '../components/PaypalDonate';

const Fundraising = () => (
  <div>
    <Tile className="about-header">
      <h1>Donating to MedEx International</h1>
      <p>
        <em>
          Thank you for your interest in contributing to our effots. There are several ways that you
          can support our mission. We are a registered 501(c)(3) Not-For-Profit organization which
          means that most donations you provide are tax-deductible.
        </em>
      </p>
    </Tile>
    <Tile className="content-tile">
      <h2>Our Funding Philosophy</h2>
      <br />
      <p>
        Here at MedEx we believe in the core of the not-for-profit philosophy. That means that 100%
        of every dollar and item we receive in our work goes directly toward providing for our
        clients and the Homeless population. None of our staff draws salary or stipend. While this
        means that we all still work our day jobs, it allows us to provide exemplary service at no
        cost to our clients through only our fundraising. We promise to provide transparency in our
        funding and spending.
      </p>
      <br />
      {/*<p>Check out our <a href='/fundraising-status'>Project Funding</a> page to see how we are using*/}
      {/*    every dollar in our efforts.</p>*/}
    </Tile>
    <Tile className="content-tile">
      <h2>Fundraising Events</h2>
      <br />
      <Grid>
        <Row>
          <Column>
            <p>
              <b>Previous Fundraising Events</b>
            </p>
            <br />
            <UnorderedList style={{ paddingLeft: 20 }}>
              <ListItem>
                <p>Lenten Mission Project at Ascension Church & Parish</p>
              </ListItem>
              <ListItem>
                <p>Contributions from Committee Donations</p>
              </ListItem>
              <ListItem>
                <p>Contributions from Direct Philanthropists</p>
              </ListItem>
            </UnorderedList>
            <br />
          </Column>
          <Column>
            <p>
              <b>Upcoming Events</b>
            </p>
          </Column>
          <Column>
            <p>
              <b>Annual Fundraising Events</b>
            </p>
            <br />
            <UnorderedList style={{ paddingLeft: 20 }}>
              <ListItem>
                <p>Annual MedEx Bike Ride</p>
                <p>2019 Summer : Milwaukee to Chicago 118 Miles</p>
                <p>2020 Fall : Milwaukee to Chicago ~115 Miles</p>
              </ListItem>
            </UnorderedList>
          </Column>
        </Row>
      </Grid>
    </Tile>
    <Tile className="content-tile">
      <h2>How Can you Help?</h2>
      <br />
      <Grid>
        <Row>
          <Column>
            <p>
              <b>Monetary Donations</b>
            </p>
            <br />
            <p>
              MedEx welcomes monetary donations of <em>any</em> size. Checks are accepted as well as
              cash, though for the safety of your donation please contact us to arrange any cash
              donations. All donations are assigned first to under-funded programs and then split to
              other areas.
            </p>
          </Column>
          <Column>
            <p>
              <b>Electronic Donations</b>
            </p>
            <br />
            <p>
              We have enabled the ability to make one time or recurring donations through our Paypal
              portal using debit/credit cards or an existing paypal balance if you like. You can
              make one time or recurring secure donation by clicking the button below.
            </p>
            <br />
            <Row style={{ marginLeft: '40%', marginRight: '60%' }}>
              <PaypalDonate />
            </Row>
          </Column>
          <Column>
            <p>
              <b>Amazon Smile</b>
            </p>
            <br />
            <p>
              We have registered with Amazon Smile in order to allow anyone to contribute to MedEx
              at
              <em> no cost</em> to them! Amazon Smile is a program where you only have to select a
              non-profit to benefit from your regular Amazon shopping every day. You can read more
              about the Amazon Smile program
              <a href="https://smile.amazon.com/gp/chpf/about/ref=smi_se_uspo_laas_aas"> here </a>
              and if you would like to support MedEx in this way go ahead and bookmark our
              <a href="https://smile.amazon.com/ref=smi_ext_ch_32?_encoding=UTF8&ein=32-0558088&ref_=smi_chpf_redirect&ref_=smi_ext_ch_32-0558088_cl">
                {' '}
                Amazon Smile homepage
              </a>
              . While this is not a tax deductible method of helping, 0.5% of each of your orders
              placed are donated to us through them. This <em>does not</em> add to your price, and
              is a form of revenue share.
            </p>
            <p>
              We allocate all donations received through the Amazon Smile program to our General
              fund.
            </p>
          </Column>
        </Row>
      </Grid>
    </Tile>
  </div>
);
export default Fundraising;
