import React from 'react';
import { Tile } from 'carbon-components-react';

const ThankYou = () => (
  <div>
    <Tile aria-label="Announcement Statement">
      <h1>Thank You!</h1>
      <p>
        From all of us here at MedEx International: Thank you for taking the time and effort to
        contribute to our cause. As we note in our description of our operational model, MedEx
        International operates completely through donations and it is through the help of people
        like you that we are able to continue to help.
      </p>
      <br />
      <p>
        Please remember to keep your receipt and check back often to see updates of how we are
        helping our partners.
      </p>
      <p>-Thank You Again</p>
    </Tile>
  </div>
);

export default ThankYou;
